const slider = (slider) => {


    const $slider = $(slider);

    $slider.owlCarousel({
        items: 1,
        slideSpeed: 1000,
        dragEndSpeed: 1000,
        navSpeed: 1000,
        dots: false,
        nav: true,
        navText: [
            '<img src="/wp-content/themes/classy/dist/img/icons/slider-prev.svg" alt="prev">',
            '<img src="/wp-content/themes/classy/dist/img/icons/slider-next.svg" alt="next">'
        ],
        dotsSpeed: 1000,
        autoplayHoverPause: true,
    });
};

export default slider;