import 'core-js/stable/promise';
import 'core-js/stable/array/from';
import 'core-js/stable/array/includes';
import 'core-js/stable/array/find-index';
import 'core-js/stable/string/includes';
import * as jQuery from 'jquery';
import * as WOW from './libs/wow.min';
import * as owlCarousel from './libs/owlCarousel/owl.carousel.min';
import * as magnificPopup from './libs/magnificPopup/jquery.magnific-popup.min';
import * as skrollr from './libs/scrollr/skrollr.min';
import './libs/pieces';


import slider from './components/slider'
import hamburgerMenu from './components/menu';
import make_popup from './components/make-popup';
import scrollToElement from './components/scroll-to-element';
import slice_image from "./components/slice-image";

window.$ = jQuery.default;

((html) => {
    html.className = html.className.replace(/\bno-js\b/, 'js');
})(document.documentElement);

$(() => {

    new WOW().init();

    setTimeout(function(){
        skrollr.init({
            mobileCheck:function(){
                return!1
            }
        })
    },100);

    slice_image('.pater', 1475, 735);
    slider('.js-slider');
    hamburgerMenu('.js-menu', '.js-hamburger');
    make_popup('.js-open-popup', 'inline');
    scrollToElement('.menu-item', 1000);
    scrollToElement('.js-scroll-to', 1000);

    if (typeof $.fn.magnificPopup === 'function') {
        const status = $('#status');

        if (status.data('status') === 'success') {
            status.click();
        }
    }


});
