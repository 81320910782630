const slice_image = (image, width, height) => {

    let $image = $(image);

    const pieces_el = $image[0];
    const obj = new Pieces(pieces_el, {
        delay: [0,30],
        pieces: {rows: 4, columns: 6},
        bgimage: $(pieces_el).data('src')
    });

    const pieces = $(obj.DOM.el).children();

    let nums = generateArrayRandomNumber(0, pieces.length);

    let i = 0;

    let interval = setInterval(function () {

        if(i > nums.length) {
            clearInterval(interval);
            setTimeout(function () {
                $(obj.DOM.el).css('background-size','cover')
            },1000)

        }
        pieces.eq(nums[i]).addClass('visible');
        i++;
    },10);

    function generateArrayRandomNumber (min, max) {
        var totalNumbers 		= max - min + 1,
            arrayTotalNumbers 	= [],
            arrayRandomNumbers 	= [],
            tempRandomNumber;

        while (totalNumbers--) {
            arrayTotalNumbers.push(totalNumbers + min);
        }

        while (arrayTotalNumbers.length) {
            tempRandomNumber = Math.round(Math.random() * (arrayTotalNumbers.length - 1));
            arrayRandomNumbers.push(arrayTotalNumbers[tempRandomNumber]);
            arrayTotalNumbers.splice(tempRandomNumber, 1);
        }

        return arrayRandomNumbers;
    }

//	Нужно учесть что в диапазоне участвуют и минимальное и максимальное число
//	тоесть если задать (0, 100) то на выходе получим массив из 101-го числа
//	от 1 до 100 и плюс число 0

};

export default slice_image;